var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableau-devoirs"},[_c('v-data-table',{staticClass:"elevation-1",class:{"light" : _vm.$store.state.Preferences.darkMode, "white" : !_vm.$store.state.Preferences.darkMode},style:({borderRadius: _vm.rounded ? "10px" : "0px"}),attrs:{"headers":_vm._headersResults,"items":_vm.sortResults,"items-per-page":_vm.limit,"options":_vm.options,"single-expand":_vm.singleExpand,"show-expand":_vm.extraContent,"expanded":_vm.expanded,"item-key":"id","search":_vm.search,"server-items-length":_vm.total,"loading":_vm.loading,"mobile-breakpoint":0,"light":"","hide-default-footer":_vm.total < _vm.maxShow,"no-data-text":_vm.search ? "Aucun résultat pour: « {search} »" : "Aucun résultat à afficher"},on:{"update:itemsPerPage":function($event){_vm.limit=$event},"update:items-per-page":function($event){_vm.limit=$event},"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticStyle:{"line-height":"1.15"}},[_c('div',[_vm._v(_vm._s(item.name))])])])]}},{key:"item.moyenne",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticStyle:{"line-height":"1.15"}},[_c('moyenne',{attrs:{"identifiant":"","id":item.id,"value":""}})],1)])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[_c('v-avatar',{staticClass:"mr2",attrs:{"color":item.status.color,"size":"6"}}),_c('span',{staticClass:"secondary--text text--lighten-3 f7"},[_vm._v(_vm._s(item.status.title))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center justify-end"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr1",attrs:{"icon":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},on),[_c('font-awesome-icon',{staticClass:"f6",attrs:{"icon":['fas', 'ellipsis-v']}})],1)]}}],null,true)},[_c('v-card',{staticClass:"pv2"},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.deleteTableau(item)}}},[_c('font-awesome-icon',{staticClass:"f6 mr2",attrs:{"icon":['fad', 'trash-alt']}}),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Supprimer")])],1)],1)],1)],1)],1),_c('v-btn',{attrs:{"icon":"","small":"","to":{ name: 'tableauDetail___', params: {id: item.identifiant ? item.identifiant : item.id } }}},[_c('font-awesome-icon',{staticClass:"f5",attrs:{"icon":['far', 'arrow-right']}})],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_vm._v("Informations supplémentaires sur {nomItem} à venir")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }