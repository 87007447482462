<script>
// import TableauBlancCard from '@/components/presentation/tableauBlancCard.vue'
import TableauTableauxBlancs from '@/components/tableauxBlancs/tableauTableauxBlancs.vue'
import { debounce } from 'lodash-es'
export default {
  name: 'Tableaux',
  components: {
    // TableauBlancCard,
    TableauTableauxBlancs
  },
  data () {
    return {
      disabledClick: false,
      searchTableaux: null,
      tableauxRecents: [],
      tableaux: [],
      loadingTableauRecents: true,
      loadingTableauxExpire: true,
      page: 1
    }
  },
  watch: {
  },
  mounted () {
    this.loadingTableauRecents = true
  },
  apollo: {
    tableauxRecents: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
      variables () {
        return {
          identifiant: true,
          batch: true,
          filtre: {
            contenus: [{
              type: 'DOSSIER'
            }]
          },
          tri: [{ sujet: 'CREATION', direction: 'DESC' }],
          limit: 6
        }
      },
      fetchPolicy: 'network-only',
      update (data) {
        this.loadingTableauRecents = false
        return data.calendrierCalendrierElements.resultat
      },
      watchLoading (isLoading) {
        this.loadingTableauRecents = isLoading
      }
    }
  },
  computed: {
    activLoadClass () {
      return this.loadingTableauRecents ? 'load__wrapper' : 'active__wrapper'
    }
  },
  methods: {
    updateSearch: debounce(function updateSearch (val) {
      this.searchTableaux = val
    }, 500, { trailing: true })
  }
}
</script>

<template lang='pug'>
  .bibliotheque-index.split-page
    div.biblio-wrapper
      //- content-handle-list(origin='tableauxBlanc', :produits='tableaux', @updateSearch='updateSearch')
      .produits-showcase
        .produit-index.split-page
          .page-header(color='primary')
            .header__inner-content
              .header-title()
                //- div.secondary-title Sous-titre
                div.main-title Tableaux blancs
              .header-description Gérez, partagez et modifiez vos tableaux blancs dans cette section.

          .page-actions
            v-card.mt-2(color='primary', :to='{ name: "editeurTableau"}', v-ripple)
              v-card-title Créer un nouveau tableau ou dupliquer un tableau existant
          .page__main-content
            section.fullsize-section.animated.fadeIn(v-if='!searchTableaux')
              .section-subtitle__header
                h2.section-subtitle(v-if='tableauxRecents.length > 0') Tableaux récents
              perfect-scrollbar.section__scroller(v-dragscroll, @dragscrollstart="disabledClick = true", @dragscrollend="disabledClick = false", :class='{"disable-click" : disabledClick}')
                .section__wrapper( :class="activLoadClass" )
                  template(v-if='loadingTableauRecents')
                    v-skeleton-loader.mr4.loading-card(type='card', v-for='(n, index) in 5')
                  template(v-else, v-for='(tableau, index) in tableauxRecents')
                    //- tableau-blanc-card.mr4(:tableau='tableau', :maxWidth='320')
            section.fullsize-section
              .section-subtitle__header
                h2.section-subtitle Tous les tableaux
              //- v-btn(:to="{ name: 'tableauDetail', params: { id: 'hardcodded' } }") Tableau hardcodded
              tableau-tableaux-blancs(:rounded='true', :search='searchTableaux')
</template>
<style lang='sass'>
  @import 'src/styles/pages/_bibliotheque'
  @import 'src/styles/components/bibliotheque/_produit'
  .active__wrapper
    max-width: 320px
    margin: 0 !important
  .load__wrapper
    margin: 0 !important
    max-width: $extra-large
    .loading-card
      .v-skeleton-loader__card
        height: 280px
        width: 320px
</style>
