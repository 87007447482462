<script>
export default {
  name: 'tableauTableauxBlancs',
  mixins: [],
  components: {
  },
  props: {
    hardcodded: { required: false, type: Boolean, default: false },
    search: { required: false, type: String, default: null },
    // usagers: { required: false, type: Array, default: () => [] },
    // salles: { required: false, type: Array, default: () => [] },
    extraContent: { required: false, type: Boolean, default: false },
    rounded: { required: false, type: Boolean, default: false },
    maxShow: { required: false, type: Number, default: 10 }
  },
  data () {
    return {
      expanded: [],
      singleExpand: true,
      nbTotal: 0,
      // devoirsActifs: null,
      fetchMore: false,
      page: 1,
      limit: 10,
      loading: true,
      limitQ: 10,
      listTableaux: null,
      tableaux: null,
      lengthDev: 0,
      options: {
        page: 1,
        limit: 25,
        itemsPerPage: this.maxShow
      },
      headersResults: [
        {
          text: 'Titre',
          align: 'left',
          sortable: true,
          value: 'name',
          show: true
        },
        { text: 'Pages', value: 'pages', sortable: true, show: true },
        { text: 'enfants', value: 'usersQty', sortable: true, show: true },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false, show: true },
        { text: '', value: 'data-table-expand', show: this.extraContent }
      ],
      hardcoddedTableaux: [
        { titre: 'Mon super tableau 1', id: '000000', usersQty: 10, pages: 2 },
        { titre: 'Mon super tableau 2', id: '000001', usersQty: 12, pages: 1 },
        { titre: 'Mon super tableau 3', id: '000002', usersQty: 4, pages: 10 }
      ]
    }
  },
  computed: {
    total () {
      let temp = 0
      if (this.tableaux && this.tableaux.total) {
        temp = this.tableaux.total
      }
      return temp
    },
    _headersResults () {
      // Certaines colonnes sont conditionnelles -> J'utilise cette computed pour les afficher / cacher
      return this.headersResults.filter(x => x.show)
    },
    sortResults () {
      let tableau = []
      if (this.hardcodded) {
        this.hardcoddedTableaux.forEach((tab, index) => {
          let entry = {}
          entry.id = tab.id
          entry.name = tab.titre
          entry.pages = tab.pages ? tab.pages : 0
          entry.usersQty = tab.usersQty
          tableau.push(entry)
        })
      } else if (this.tableaux && this.tableaux.total > 0) {
        this.tableaux.resultat.forEach((tab) => {
          tableau.push({
            id: tab.batch,
            identifiant: tab.identifiant,
            name: tab.titre,
            description: tab.description,
            pages: tab.contenu.tableauxBlancs[0]?.nbPage || 0,
            usersQty: tab.elementsUsagers.total,
            dossierId: tab.contenu?.id,
            tableauEnregistrementId: tab?.contenu?.tableauxBlancs[0]?.id
          })
        })
      }
      return tableau
    },
    tableauBlancToEdit: {
      get () { return this.$store.state.Dialogs.tableauBlancToEdit },
      set (val) { this.$store.commit('Dialogs/setTableauBlancToEdit', val) }
    },
    showTableauBlancSuppression: {
      get () { return this.$store.state.Dialogs.showTableauBlancSuppression },
      set (val) { this.$store.commit('Dialogs/setShowTableauBlancSuppression', val) }
    }
  },
  watch: {
    options: {
      immediate: true,
      handler (newVal) {
        this.loading = true
        this.page = newVal.page
        this.fetchMore = false
        if (newVal.itemsPerPage !== -1) {
          this.limitQ = newVal.itemsPerPage
        } else {
          this.limitQ = 100
        }
      }
    },
    '$store.state.App.snackbarGlobalContent': {
      deep: true,
      handler (newVal) {
        if (newVal && newVal.description && newVal.description.includes('Le tableau blanc a bien été supprimé.')) {
          this.$apollo.queries.tableaux.refetch()
        }
      }
    }
  },
  apollo: {
    tableaux: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
      variables () {
        return {
          filtre: {
            contenus: [{
              type: 'DOSSIER',
              expire: false
            }]
          },
          tri: [{ sujet: 'CREATION', direction: 'DESC' }],
          batch: true,
          identifiant: true,
          page: this.page,
          limit: 10,
          q: this.search
        }
      },
      update (data) {
        this.loading = false
        return data.calendrierCalendrierElements
      },
      fetchPolicy: 'network-only'
    }
  },
  mounted () {
  },
  methods: {
    deleteTableau (tableau) {
      if (tableau) {
        this.tableauBlancToEdit = {
          id: tableau.identifiant,
          titre: tableau.name ? tableau.name : 'Groupe sans nom',
          description: tableau.description ? tableau.description : null,
          dossier: tableau.dossierId,
          tableauEnregistrementId: tableau.tableauEnregistrementId
        }
      } else {
        this.tableauBlancToEdit = {
          id: '0000000000',
          titre: 'Tableau sans nom (HARDCODDED)',
          description: 'Aucune description disponible'
        }
      }
      this.showTableauBlancSuppression = true
    }
  }
}
</script>

<template lang='pug'>
  .tableau-devoirs
    v-data-table.elevation-1(
      :headers='_headersResults'
      :items='sortResults'
      :items-per-page.sync='limit'
      :options.sync='options'
      :single-expand='singleExpand'
      :show-expand='extraContent'
      :expanded.sync="expanded"
      item-key='id'
      :search='search'
      :server-items-length='total'
      :loading='loading'
      :mobile-breakpoint='0'
      light
      :class='{"light" : $store.state.Preferences.darkMode, "white" : !$store.state.Preferences.darkMode}'
      :style='{borderRadius: rounded ? "10px" : "0px"}',
      :hide-default-footer='total < maxShow',
      :no-data-text='search ? "Aucun résultat pour: « {search} »" : "Aucun résultat à afficher"'
    )
      template(v-slot:item.name='{ item }')
        div.flex.items-center
          div(style='line-height:1.15')
            div {{item.name}}
      template(v-slot:item.moyenne='{ item }')
        div.flex.items-center
          div(style='line-height:1.15')
            moyenne(identifiant=true, :id='item.id', value=true)
      template(v-slot:item.status='{ item }')
        div.flex.items-center
          v-avatar.mr2(:color='item.status.color', size='6')
          span.secondary--text.text--lighten-3.f7 {{ item.status.title }}
      template(v-slot:item.actions='{ item }')
        div.flex.items-center.justify-end
          v-menu(offset-y)
            template(v-slot:activator='{ on }')
              v-btn.mr1(icon,small, @click.prevent.stop='', v-on='on')
                font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
            v-card.pv2
              v-list(dense, nav)
                v-list-item(@click='deleteTableau(item)')
                  font-awesome-icon.f6.mr2(:icon="['fad', 'trash-alt']")
                  v-list-item-content
                    v-list-item-title Supprimer
          v-btn(icon, small, :to="{ name: 'tableauDetail___', params: {id: item.identifiant ? item.identifiant : item.id } }")
            font-awesome-icon.f5(:icon="['far', 'arrow-right']")
      template(v-slot:expanded-item='{ headers, item }')
        td(:colspan="headers.length")
          div Informations supplémentaires sur {nomItem} à venir
</template>
<style lang='sass'>
  @import 'src/styles/pages/_groupeDetail'
  .tableau-devoirs
    .v-data-table
      tbody
        tr
          td
            font-size: 12px !important
</style>
